<template>
  <div class="iframe-box">
      <!-- <div class="back" @click="back"><i class="el-icon-back"></i>返回</div> -->
    <iframe id="iframe-page" src></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  //   watch: {
  //     $route(to, from) {
  //       console.log(this.$route);
  //       console.log(to, this.$route.params.iframeUrl);
  //       if (to.path && this.$route.params.iframeUrl) {
  //         document.getElementById('iframe-page').src = '';
  //         setTimeout(() => {
  //           document.getElementById('iframe-page').src = this.$route.params.iframeUrl;
  //         }, 300);
  //       }
  //     },
  //   },
  mounted() {
    console.log(this.$route.params.iframeUrl);
    setTimeout(() => {
      document.getElementById('iframe-page').src = this.$route.params.iframeUrl;
    }, 300);
  },
  computed: {},
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='scss' scoped>
.wrapper {
}
.iframe-box {
  height: calc(100vh - 168px);
  background-color: #fff;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  .back {
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin: 20px 100px 0;
    i {
      margin-right: 5px;
      font-size: 18px;
    }
  }
}
</style>
